<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="8">
          <b-card-title>
            <h2 class="content-header-title float-left pr-1 mb-0">
              <!-- {{ this.accountName }} -->
              Stores
            </h2>
          </b-card-title>
          <br />
          <b-card-title>
            <h5 class="float-left pr-1 mb-0">
              You've {{ this.count }} stores across the India.
            </h5>
          </b-card-title>
        </b-col>
        <b-col cols="4">
          <div class="form-group">
            <label for="monthSelect" class="form-label"> Month:</label>
            <div class="select-container">
              <b-form-select
                id="monthSelect"
                v-model="selectedMonth"
                @change="getUpdateAllTableData"
              >
                <option
                  v-for="month in months"
                  :value="month.value"
                  :key="month.value"
                >
                  {{ month.label }}
                </option>
              </b-form-select>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- Summary Insights from GMB Table -->
    <b-card>
      <b-card-title
        :class="visible1st ? null : 'collapsed'"
        :aria-expanded="visible1st ? 'true' : 'false'"
        aria-controls="collapse-4"
        @click="visible1st = !visible1st"
      >
        <div class="collapse-menu">
          <b-row>
            <b-col cols="6">
              <h3 class="app-title mdsm">
                Summary Insights from GMB
              </h3>
            </b-col>
            <b-col cols="6">
              <feather-icon
                class="collapse-icon text-primary"
                v-if="visible1st"
                icon="MinusIcon"
                size="18"
              />
              <feather-icon
                class="collapse-icon text-primary"
                v-if="!visible1st"
                icon="PlusIcon"
                size="18"
              />
            </b-col>
          </b-row>
        </div>
      </b-card-title>
      <b-collapse id="collapse-4" v-model="visible1st" class="mt-2">
        <vue-good-table
          :columns="SummarytableColumns"
          :rows="SummaryData"
          class="custom-data-table"
          :pagination-options="{
            enabled: true,
            perPage: summaryPageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'percentageChange'">
              <span>
                {{ Math.abs(props.row.percentageChange) }} %
                <feather-icon
                  class="up-icon"
                  v-if="props.row.percentageChange > 0"
                  icon="ArrowUpIcon"
                  size="18"
                />
                <feather-icon
                  v-if="props.row.percentageChange < 0"
                  icon="ArrowDownIcon"
                  size="18"
                  class="down-icon"
                />
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="summaryPageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                  v-model="currentPage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-collapse>
    </b-card>
    <!-- Organic vs Paid Table -->
    <b-card>
      <b-card-title
      :class="visible3rd ? null : 'collapsed'"
      :aria-expanded="visible3rd ? 'true' : 'false'"
      aria-controls="collapse-4"
      @click="visible3rd = !visible3rd"
    >
      <div class="collapse-menu">
        <b-row>
          <b-col cols="6">
            <h3 class="app-title mdsm">
             Organic vs Paid
            </h3>
          </b-col>
          <b-col cols="6">
            <feather-icon
              class="collapse-icon text-primary"
              v-if="visible3rd"
              icon="MinusIcon"
              size="18"
            />
            <feather-icon
              class="collapse-icon text-primary"
              v-if="!visible3rd"
              icon="PlusIcon"
              size="18"
            />
          </b-col>
        </b-row>
      </div>
    </b-card-title>
    <b-collapse id="collapse-4" v-model="visible3rd" class="mt-2">
<vue-good-table :columns="OrgVsPaidTable" :rows="OrgvsPaid">
  <template slot="table-row" slot-scope="props">
    <div v-if="props.column.field == 'delta'">
      <span>
        {{ Math.abs(props.row.delta) }} %
        <feather-icon
          class="up-icon"
          v-if="props.row.delta > 0"
          icon="ArrowUpIcon"
          size="18"
        />
        <feather-icon
          v-if="props.row.delta < 0"
          icon="ArrowDownIcon"
          size="18"
          class="down-icon"
        />
      </span>
    </div>
    <span v-else>
      {{ props.formattedRow[props.column.field] }}
    </span>
  </template>
</vue-good-table>
      </b-collapse>
    </b-card>

    <!-- Paid Ads Effectiveness (Data from adwords) Table-->
    <b-card>
      <b-card-title
      :class="visible4th ? null : 'collapsed'"
      :aria-expanded="visible4th ? 'true' : 'false'"
      aria-controls="collapse-4"
      @click="visible4th = !visible4th"
    >
      <div class="collapse-menu">
        <b-row>
          <b-col cols="6">
            <h3 class="app-title mdsm">
              Paid Ads Effectiveness 
            </h3>
          </b-col>
          <b-col cols="6">
            <feather-icon
              class="collapse-icon text-primary"
              v-if="visible4th"
              icon="MinusIcon"
              size="18"
            />
            <feather-icon
              class="collapse-icon text-primary"
              v-if="!visible4th"
              icon="PlusIcon"
              size="18"
            />
          </b-col>
        </b-row>
      </div>
    </b-card-title>
    <b-collapse id="collapse-4" v-model="visible4th" class="mt-2">
<vue-good-table :columns="PaidAdsTable" :rows="PaidAds">
  <template slot="table-row" slot-scope="props">
    <div v-if="props.column.field == 'delta'">
      <span>
        {{ Math.abs(props.row.delta) }} %
        <feather-icon
          class="up-icon"
          v-if="props.row.delta > 0"
          icon="ArrowUpIcon"
          size="18"
        />
        <feather-icon
          v-if="props.row.delta < 0"
          icon="ArrowDownIcon"
          size="18"
          class="down-icon"
        />
      </span>
    </div>
    <span v-else>
      {{ props.formattedRow[props.column.field] }}
    </span>
  </template>
</vue-good-table>
      </b-collapse>
    </b-card>
    <!-- Individual Store Data Table -->
    <div>
      <b-spinner v-if="isFetchingStoreData" small class="mr-1 spinner" />
      <b-card>
        <b-row>
          <b-col
            cols="8"
            :class="visible2nd ? null : 'collapsed'"
            :aria-expanded="visible2nd ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visible2nd = !visible2nd"
          >
            <b-card-title>
              <h3 class="app-title mdsm">
                Individual Store Data
              </h3>
            </b-card-title>
          </b-col>
          <b-col cols="" class="input-container">
            <input
              v-if="visible2nd"
              type="text"
              v-model="searchTerm"
              placeholder="Search by fields"
            />
          </b-col>
          <b-col
            cols="1"
            :class="visible2nd ? null : 'collapsed'"
            :aria-expanded="visible2nd ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visible2nd = !visible2nd"
          >
            <feather-icon
              class="collapse-icon text-primary"
              v-if="visible2nd"
              icon="MinusIcon"
              size="18"
            />
            <feather-icon
              class="collapse-icon text-primary"
              v-if="!visible2nd"
              icon="PlusIcon"
              size="18"
            />
          </b-col>
        </b-row>
        <b-collapse id="collapse-4" v-model="visible2nd" class="mt-2">
          <vue-good-table
            :columns="columns"
            :rows="StoresData"
            class="custom-data-table"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.label == 'Store Address'">
                <span
                   v-b-tooltip.hover
                  class="tooltip-trigger overflow-text"
                  :title="props.row.Address"
                >
                  {{ props.row.Address }}
                </span>
              </div>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                    v-model="currentPage"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BCol,
  BRow,
  BPagination,
  BSpinner,
  BCollapse,
} from "bootstrap-vue";
import axios from "axios";
import { ArrowUpIcon, ArrowDownIcon } from "vue-feather-icons";
import { getUserToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import {sortFuntionNumericVal, sortFuntionStringintoNumericVal } from '@/components/client/SortingFunction';
export default {
  name: "Stores",
  components: {
    BSpinner,
    BCard,
    BCardTitle,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BCol,
    BRow,
    VueGoodTable,
    BPagination,
    ArrowUpIcon,
    ArrowDownIcon,
    BCollapse,
  },
  data() {
    return {
      visible1st: true,
      visible2nd: true,
      visible3rd:true,
      visible4th:true,
      pageLength: 5,
      summaryPageLength: 10,
      selectedMonth: 3,
      count: 0,
      accountName: "",
      StoresData: [],
      SummaryData: [],
      OrgvsPaid:[],
      PaidAds:[],
      maxLength: 30,
      searchTerm: "",
      isFetchingStoreData: false,
      currentPage: 1,
      months: [
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
      ],
      columns: [
        {
          label: "Store code",
          field: "Store code",
          filterable: true,
          thClass: "text-center",
          sortable: false
        },
        {
          label: "Business name",
          field: "Business name",
          type: "number",
          filterable: true,
          thClass: "text-center",
          sortable: false
        },
        {
          label: "Store Address",
          field: "-",
          filterable: true,
          thClass: "text-center",
          sortable: false
        },
        {
          label: "Overall rating",
          field: "Overall rating",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Total searches",
          field: "Total searches",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Direct searches",
          field: "Direct searches",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Discovery searches",
          field: "Discovery searches",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Total views",
          field: "Total views",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Search views",
          field: "Search views",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Maps views",
          field: "Maps views",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Total actions",
          field: "Total actions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Website actions",
          field: "Website actions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Directions actions",
          field: "Directions actions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Phone call actions",
          field: "Phone call actions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
      ],
    };
  },
  methods: {
    sortFn(a,b){
      return sortFuntionNumericVal(a,b);
    },
    sortStringtoNumFn(a,b){
      return sortFuntionStringintoNumericVal(a,b);
    },
    getUpdateAllTableData(){
      this.getStoresData();
      this.getPaidvsOrgData();
      this.getPaidAdsData();
    },
    getStoresData() {
      this.isFetchingStoreData = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/stores/fetch?month=${this.selectedMonth}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.isFetchingStoreData = false;
          this.StoresData = response.data[0].data;
          this.SummaryData = response.data[0].summaryInsights;
          this.accountName = response.data[0].accountName;
          this.count = response.data[0].count;
          this.currentPage = 1;
        })
        .catch((error) => {
          this.StoresData = [];
        });
    },
    getPaidvsOrgData() {
      this.isFetchingStoreData = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/demo/visitors?month=${this.selectedMonth}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.OrgvsPaid = response.data;
        })
        .catch((error) => {
          this.StoresData = [];
        });
    },
    getPaidAdsData() {
      this.isFetchingStoreData = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/paid-ads-effectiveness?month=${this.selectedMonth}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.PaidAds = [];
          for (let data of response.data) {
          data["value"] = data["unit"] + " " + data["value"]
          this.PaidAds.push(data);
          }
        })
        .catch((error) => {
          this.StoresData = [];
        });
    },
  },
  mounted() {
    this.getStoresData();
    this.getPaidvsOrgData();
    this.getPaidAdsData();
  },
  computed: {
    SummarytableColumns() {
      const summaryLabel = `${this.months[this.selectedMonth - 1].label} 2023`;
      return [
        {
          label: "Metric",
          field: "title",
          sortable: false
        },
        {
          label: summaryLabel,
          field: "value",
          sortFn: this.sortFn
        },
        {
          label: "Change over previous month",
          field: "percentageChange",
          sortable: true,
          sortFn: this.sortFn
        },
      ];
    },
    OrgVsPaidTable() {
      const summaryLabel = `${this.months[this.selectedMonth - 1].label} 2023`;
      let columns = [
        {
          label: "Metric",
          field: "title",
          sortable: false
        },
        {
          label: summaryLabel,
          field: "value",
          sortFn: this.sortFn
        },
        {
          label: "Change over previous month",
          field: "delta",
          sortFn: this.sortFn
        },
      ];
      return columns;
    },
    PaidAdsTable() {
      const summaryLabel = `${this.months[this.selectedMonth - 1].label} 2023`;
      let columns = [
        {
          label: "Metric",
          field: "title",
          sortable: false
        },
        {
          label: summaryLabel,
          field: "value",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Change over previous month",
          field: "delta",
          sortFn: this.sortFn
        },
      ];
      return columns;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.form-group {
  display: flex;
  align-items: center;
}
.form-label {
  margin-right: 10px;
  font-size: 15px;
}
.select-container {
  flex: 1;
}
.stores-header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 205px;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.spinner {
  margin-left: 50%;
}
.text-center {
  text-align: center;
}
.tooltip-trigger {
  position: relative;
  cursor: pointer;
}
.up-icon {
  margin-left: 10px;
  color: green;
  align-items: center;
}
.down-icon {
  margin-left: 10px;
  color: red;
  align-items: center;
}
.collapse-icon {
  color: grey;
  float: right;
  cursor: pointer;
}
.input-container {
  float: right;
}
.overflow-text {
  display: block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}
.form-group {
  display: block !important;
}
.form-label {
   font-size: 12px !important;
}
</style>
